import React, { createContext, useContext } from 'react';

const SiteContext = createContext();

export const SiteProvider = ({ children }) => {
  const siteInfo = {
    siteName: "GreatGamings.com",
    slogan: "Level Up Your Bedroom",
    info: "We offer a curated selection of top-quality night stands to enhance your living space.",
    thankYouProduct: "Thanks for checking out our amazing night stands! Your interest means the world to us.",
    thankYouSubscription: "You're officially part of the GreatGamings family! Thanks for subscribing to our newsletter.",
    thankYouOrder: "Your order is on its way to making your nights more stylish and organized. Thanks for choosing us!",
  };

  return (
    <SiteContext.Provider value={siteInfo}>
      {children}
    </SiteContext.Provider>
  );
};

// Create a custom hook to use the SiteContext
export const useSite = () => {
  return useContext(SiteContext);
};
