import image1 from '../assets/image1.webp';
import image2 from '../assets/image2.webp';
import image3 from '../assets/image3.webp';
import image4 from '../assets/image4.webp';
import image5 from '../assets/image5.webp';
import image6 from '../assets/image6.webp';
import image7 from '../assets/image7.webp';
import image8 from '../assets/image8.webp';
import image9 from '../assets/image9.webp';

const products = [
  { id: 1, name: 'Spinal Chord Night Stand', price: '24.59', image: image1, badge: 'New', description: 'This Spinal Chord Night Stand features a sleek design with a unique spinal column-inspired structure, making it a perfect addition to modern interiors. Ideal for those who appreciate innovative design and functionality.' },
  { id: 2, name: 'Retro Wooden Night Stand', price: '43.67', image: image2, badge: 'New', description: 'Crafted from high-quality wood, the Retro Wooden Night Stand brings a touch of nostalgia to any bedroom. Its robust construction and timeless design ensure it complements both vintage and contemporary decor.' },
  { id: 3, name: 'Retro Couple Night Stand', price: '29.95', image: image3, badge: 'New', description: 'Designed for couples, this Retro Couple Night Stand offers ample storage and a charming retro look. It’s perfect for those who love blending classic style with practical features.' },
  { id: 4, name: 'Cute Snail Night Stand', price: '32.95', image: image4, badge: 'Sale', description: 'The Cute Snail Night Stand is not only adorable but also highly functional. Its whimsical design is perfect for children’s rooms or anyone who enjoys playful decor. Now on sale, it’s a great time to add this charming piece to your home.' },
  { id: 5, name: 'Lovecats Night Stand', price: '30.99', image: image5, badge: 'Sale', description: 'Featuring a delightful cat motif, the Lovecats Night Stand is a must-have for cat lovers. It combines style and utility with its sturdy build and playful design, making it a standout piece in any bedroom.' },
  { id: 6, name: 'Sun Night Stand', price: '12.00', image: image6, badge: 'Sale', description: 'Brighten up your room with the Sun Night Stand, available at a budget-friendly price. Its sunny design and compact size make it perfect for small spaces, while its sale price makes it an unbeatable deal.' },
  { id: 7, name: 'Starlight Night Stand', price: '7.90', image: image7, badge: 'Exclusive', description: 'Exclusive and innovative, the Starlight Night Stand is designed to impress. Its star-themed design and elegant finish make it a stellar addition to any bedroom, setting a new standard in night stand design.' },
  { id: 8, name: 'Hidden World Night Stand', price: '59.00', image: image8, badge: 'Exclusive', description: 'The Hidden World Night Stand offers a blend of mystery and functionality with its concealed compartments and sleek appearance. It’s perfect for those who value both style and practicality in their furniture.' },
  { id: 9, name: 'Flower Pot Night Stand', price: '30.99', image: image9, badge: 'Exclusive', description: 'Combining nature and functionality, the Flower Pot Night Stand features an integrated pot for your favorite plant. This luxurious item not only serves as a night stand but also brings a touch of greenery into your living space.' },
];

export default products;
